
import { defineComponent } from 'vue'
import {
  Diagram,
  DiagramsFileKey,
  DiagramStoreActions,
  DiagramTypes,
} from '@interfaces/DiagramTypes'
import CommonCustomDiagramCardViewer from '~/components/common/CommonCustomDiagramCardViewer.vue'
import { mapActions } from 'vuex'
import { getThumbnailUrl } from '@helpers/DiagramHelper'
export default defineComponent({
  name: 'HomepageJoinCommunity',
  components: { CommonCustomDiagramCardViewer },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      diagram: [] as Diagram[],
    }
  },
  mounted() {
    this.fetchDiagram()
  },
  async fetch(): Promise<void> {
    const { $store } = this
    this.loading = true

    await $store.dispatch(DiagramStoreActions.GET_DIAGRAMS, {
      context: this,
      params: {
        diagramType: DiagramTypes.POPULAR,
        diagramPage: 1,
        authenticatedUser: $store.getters['userIsAuthenticated'],
        count: 8,
      },
    })

    await $store.dispatch('designers/fetchDesignersList', {
      context: this,
      page: 1,
      type: 'popular',
      authenticatedUser: $store.getters['userIsAuthenticated'],
      count: 8,
      getLastSeen: true,
      allUsersCount: true,
    })

    this.loading = false
  },
  computed: {
    DiagramTypes() {
      return DiagramTypes
    },
    highlightedDiagrams() {
      return this.$store.state.diagrams?.highlighted?.slice(0, 4)
    },
    totalDiagrams() {
      return this.simplifyNumber(this.$store.state.diagrams?.total || 0) || 0
    },
    totalDesigners() {
      return this.simplifyNumber(this.$store.state.designers?.total || 0) || 0
    },
    displayedDiagrams() {
      return this.diagram
    },
    diagramThumbnailUrl(): string {
      return this.$config.apiBaseURL + getThumbnailUrl(this.diagram[0])
    },
  },
  methods: {
    getDiagramsByFileKey: {} as DiagramsFileKey,
    ...mapActions('diagrams', ['getDiagramsByFileKey']),
    async fetchDiagram() {
      if (this.block?.diagrams) {
        const fileKeys = Object.values(this.block.diagrams).map(
          //@ts-ignore
          (diagramObject) => diagramObject.fuid
        )
        this.diagram = []
        const promises = fileKeys.map((fileKey) =>
          this.getDiagramsByFileKey({
            context: this.$nuxt.context,
            params: { fileKeys: [fileKey] },
          })
        )

        try {
          const diagramsArray = await Promise.all(promises)
          this.diagram = diagramsArray.flat()
        } catch (error) {
          console.error(`Error fetching diagrams:`, error)
        }
      }
    },
    simplifyNumber: function (num: number): string {
      try {
        let units = ['', 'k', 'M']
        let order = 0
        while (num >= 1000 && order < units.length - 1) {
          order++
          num = num / 1000
        }
        if (order === 0) {
          return num.toString()
        }
        return num.toFixed(1) + units[order]
      } catch (error) {
        console.warn('Error simplifying number', error)
        return 'N/A'
      }
    },
  },
})
